import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuiteHeader = _resolveComponent("SuiteHeader")!
  const _component_NotAuthHeader = _resolveComponent("NotAuthHeader")!
  const _component_SubHeader = _resolveComponent("SubHeader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["View", {'fullScreen':!_ctx.showHeader()}])
  }, [
    _withDirectives(_createVNode(_component_SuiteHeader, null, null, 512), [
      [_vShow, _ctx.showHeader() && _ctx.$router.currentRoute.value.name != 'watmonitor']
    ]),
    _withDirectives(_createVNode(_component_NotAuthHeader, { showBorder: false }, null, 512), [
      [_vShow, !_ctx.showHeader()]
    ]),
    _withDirectives(_createVNode(_component_SubHeader, null, null, 512), [
      [_vShow, _ctx.showSubHeader()]
    ]),
    _createVNode(_component_router_view, { class: "main-content" })
  ], 2))
}